import { useState, useCallback, useContext } from 'react';
import { GlobalContext } from '../GlobalContext';
import useAuth from './useAuth';
import useLocalStorage from './useLocalStorage';

export const usePlayerData = (gameName) => {
    const [error, setError] = useState(null);
    const { setGameSessionData } = useContext(GlobalContext);
    const { login } = useAuth();
    const [storedValue] = useLocalStorage('token', null);

    const fetchPlayerData = useCallback(async (retry = true) => {
        // console.log("Checking manually for ready player")
        setError(null); // Reset error state
        
        let gameStatusId;
        switch(gameName) {
            case 'puzzle':
                gameStatusId = 1;
                break;
            case 'firewall':
                gameStatusId = 2;
                break;
            case 'crack-the-safe':
                gameStatusId = 3;
                break;
            default:
        }

        if(storedValue() === null) {
            await login();
            return fetchPlayerData();
        } else {
            try {
                const endpoint = process.env.REACT_APP_API_ENDPOINT;

                const response = await fetch(`${endpoint}/GameSession/PlayersReady/${gameStatusId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedValue()}`
                    }
                });

                // console.log("response")
                // console.log(response)

                if (!response.ok) {
                    if(response.status === 401 && retry) {
                        await login();
                        await fetchPlayerData(false);
                    } else {
                        throw new Error(`Error: ${response.status} ${response.statusText}`);
                    }
                } else {
                    const data = await response.json();
        
                    if (!data.data || data.data.length === 0) {
                        // setTimeout(()=>fetchPlayerData(retry), 2000); // if no player ready, look again after 2 seconds
                        return;
                    }
        
                    const playerInfo = data.data[0];
        
                    setGameSessionData(prevData => ({
                        ...prevData,
                        alias: playerInfo.alias,
                        currentrfidtagid: playerInfo.currentrfidtagid,
                        gamesessionid: playerInfo.gamesessionid
                    }));

                    // console.log("logging game data", playerInfo);
                }

            } catch (error) {
                // console.error("Error fetching player data:", error.message);
                setError(error.message);
            }
        }
    }, [gameName, setGameSessionData, login, storedValue]);

    return { error, fetchPlayerData };
};
