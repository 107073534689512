// import { useContext } from 'react';
// import { GlobalContext } from '../GlobalContext';
import useLocalStorage from "./useLocalStorage";

const useAuth = () => {
    // const { gameSessionData, setGameSessionData } = useContext(GlobalContext);
    const [setValue] = useLocalStorage('token', null);

    const login = async () => {
        const endpoint = process.env.REACT_APP_API_ENDPOINT;

        const response = await fetch(`${endpoint}/auth/admin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: "playmaxx-admin@2dm.net.au",
                password: process.env.REACT_APP_AUTH_PASSWORD
            })
        });

        if (!response.ok) {
            throw new Error('Login failed');
        }

        const data = await response.json();

        setValue(data.token);

        return data.token;
    };

    return { login };
};

export default useAuth;
