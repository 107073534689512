export default function useLocalStorage(key, initialValue) {
    const storedValue = () => {
        try {
            const item = window.localStorage.getItem(key);
            console.log("Retrieved Token");
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(`Error reading localStorage key “${key}”:`, error);
            return initialValue;
        }
    };

    const setValue = (value) => {
        try {
          const valueToStore = typeof value === 'function' ? value(storedValue()) : value;
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
          console.log("Stored Token");
          console.log(valueToStore);
        } catch (error) {
          console.error(`Error setting localStorage key “${key}”:`, error);
        }
      };
      

    return [storedValue, setValue];
}