import { useState, useEffect, useCallback, useContext } from 'react';
import { GlobalContext } from '../GlobalContext';
import useLocalStorage from './useLocalStorage';
import useAuth from './useAuth';

const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
};

export const useGetGameData = (gameId) => {
    const [error, setError] = useState(null);
    const [ gameStateData, setGameStateData] = useState(null);
    const { gameSessionData } = useContext(GlobalContext);
    const [ storedValue ] = useLocalStorage('token', null);
    const { login } = useAuth();

    const getGameData = useCallback(async () => {
        if(storedValue() === null) {
            await login();
            return getGameData();
        } else {
            try {
                const endpoint = process.env.REACT_APP_API_ENDPOINT;
    
                const response = await fetch(`${endpoint}/GameSetting?gameSeasonId=${gameId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedValue()}`
                    },
                });
    
                if (!response.ok) {
                    const errorMessage = `Error: ${response.status} ${response.statusText}`;

                    if(response.status === 401) {
                        await login();
                        return getGameData();
                    }

                    throw new Error(errorMessage);
                }
    
                const responseData = await response.json();
    
                if(gameId === 2) {
                    // firewall also needs questions and pin
                    const responsePin = await fetch(`${endpoint}/GameSessionSetting?gameSessionId=${gameSessionData.gamesessionid}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${storedValue()}`
                        },
                    });
    
                    if (!responsePin.ok) {
                        const errorMessage = `Error: ${responsePin.status} ${responsePin.statusText}`;
                        throw new Error(errorMessage);
                    }
    
                    const pinNumber = await responsePin.json();
    
                    setGameStateData({
                        questionsData: shuffleArray(JSON.parse(responseData.items[0].settingData).questions),
                        pin: JSON.parse(pinNumber.items[0].sessionSettings)["PIN"].split(''),
                        timeOut: JSON.parse(responseData.items[0].settingData).timeLimits.readyTimeout // time is in seconds
                    })
                } else {
                    setGameStateData({
                        timeOut: JSON.parse(responseData.items[0].settingData).timeLimits.readyTimeout // time is in seconds
                    })
                }
                
            } catch (error) {
                console.error("Error updating session status:", error.message);
                setError(error.message);
            }
                    
        }
    }, [storedValue, login, gameId, gameSessionData.gamesessionid]); // Ensure all relevant dependencies are included

    useEffect(() => {
        getGameData();
    }, [getGameData]);

    return { error, getGameData, gameStateData };
};
